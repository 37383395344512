import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import Header from "../components/header"
import Container from "../components/container"
import AnimationWrapper from "../components/animationWrapper"
import TextSection from "../components/textSection"
import IconSection from "../components/iconSection"
import ContactSection from "../components/contactSection"
import DescriptionSection from "../components/descriptionSection"
import Layout from "../components/layout"

import "./o-marce.css"

const AboutUsPage = ( {data, location} ) => {

  // map catalogue link to catalogue section
  const catalogueDesc = data.page.frontmatter.catalogue_section;
  catalogueDesc.button_url = data.catalogue.frontmatter.file.publicURL;

  return (

    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      <AnimationWrapper>
        <Header pageName="O marce" data={data.header} />
      </AnimationWrapper>

      <Container>
        <TextSection className="oferta-description description-medium" data={data.page.frontmatter.text_section_main} />
      </Container>

      <section id="about-desc" className="page-section">
        {data.page.frontmatter.description_sections.map((description_section, key) => (
            <DescriptionSection key={key} location="o-marce" data={description_section} />
        ))}
      </section>

      <section id="catalogue-section" className="page-section">
        <DescriptionSection location="catalogue" data={catalogueDesc} />
      </section>

      <section id="homepage-icons">
        <Container>
          <AnimationWrapper>
            <IconSection data={data.page.frontmatter.icon_section} location="homepage" />
          </AnimationWrapper>
        </Container>
      </section>

      <section id="contact-data" className="page-section">
        <ContactSection data={data.page.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "o-marce" }, type: { eq: "page" } }) {
      frontmatter{
        title
        text_section_main{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
        description_sections{
          title
          title_visible
          subtitle
          align
          text
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          object_fit
        }
        catalogue_section{
          title
          title_visible
          subtitle
          align
          text
          button_text
          button_url
          button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          object_fit
        }
        icon_section{
          type
          text_section{
            title
            title_visible
            subtitle
            align
            # image{
            #   childImageSharp{
            #     gatsbyImageData(
            #       webpOptions: {quality: 95}
            #     )
            #   }
            # }
            text
          }
          icons{
            title
            text
            image{
              childImageSharp {
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
          }
        }
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    catalogue: mdx(
      internal: {contentFilePath: {regex: "/(downloads)/"}},
      frontmatter: {category: {eq: "katalogi"}}
    ) {
      frontmatter {
        name
        file {
          publicURL
        }
      }
      id
    }
    header: file(relativePath: {eq: "header-dark.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 95
          transformOptions: {fit: COVER}
          webpOptions: {quality: 95}
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default AboutUsPage
